
(function ($) {
    console.log('init global functions');

    'use strict';

    let timeSearchTable = 0;

    const isNotEmpty = (el) => {
        if (el == null || el.length == 0 || el.length == undefined || el == 'null' || el == '') {
            return false;
        }
        return true;
    }

    $('.search-input').on('change', function () {
        let inValue = JSON.stringify($(this).val());
        if (isNotEmpty(inValue)) {
            let id = $(this).attr('id') ?? '';
            let itemId = `${id}_${$(this).prop("type")}__${i_url}`;
            localStorage.setItem(itemId, inValue);
            clearTimeout(timeSearchTable);
            timeSearchTable = setTimeout(function () {
                initTable();
            }, 1100);
        }

    })

    $.each($('.search-input'), function () {
        let id = $(this).attr('id') ?? '';
        let itemId = `${id}_${$(this).prop("type")}__${i_url}`;
        let value = JSON.parse(localStorage.getItem(itemId));
        if (isNotEmpty(value)) {
            if ($(this).attr('type') == 'checkbox') {
                $(this).val(value).trigger('change')
            } else {
                $(this).val(value).trigger('change')
            }
        }
    });

    //$('.js-btn-delete').on('click', function () {
    //    var $modal = $("#modal-confirm-delete")
    //    var href = $(this).attr('data-href');
    //    var text = $(this).attr('data-text');
    //    if (text != null) {
    //        $modal.find('.modal-title').html(`${text}`);
    //        $modal.find('.btn-danger').html(`<i class="fas fa-exclamation-circle"></i> ${text}`);
    //        $modal.find('.modal-text-body').html(`Tem certeza que deseja ${text}?`);
    //    }
    //    $modal.find('#form-delete').attr('action', href)
    //    $modal.modal('show');
    //});

    let timeButtonConfirm = 0;
    let click = 0;

    $('.js-btn-confirm').on('click', function (e) {
        e.preventDefault();
        const btn = $(this);
        click++;

        btn.html('<i class="fa fa-exclamation-circle"></i>');
        btn.addClass("js-click-to-confirm");

        if (click == 2) {
            var form = btn.closest("form");
            form.submit();
        }

        clearTimeout(timeButtonConfirm);
        timeButtonConfirm = setTimeout(function () {
            btn.html('<i class="fa fa-trash"></i>');
            btn.removeClass("js-click-to-confirm");
            click = 0;
        }, 1900);
    });

    $('.copyToClipboard').on('click', function () {
        $(this).select();
        document.execCommand("copy");
        toastr.success('texto copiado!')
    })

    $('.js-btn-m-confirm').on('click', function () {
        var token = $('meta[name="csrf-token"]').attr('content');
        var action = $(this).attr('data-href') != undefined ? $(this).attr('data-href') : null;
        var title = $(this).attr('title') != undefined ? $(this).attr('title') : 'Editar';
        var html = `
        <div class="modal fade effect-scale" id="modal-confirm-confirm" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title"></h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="mg-b-0 modal-text-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="modal-cancel" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <form id="form-confirm" role="form" class="needs-validation" action="${action}" method="POST">
                            <input type="hidden" name="_token" value="${token}">
                            <button type="submit" id="modal-confirm" data-btn-text="Salvando" class="btn btn-success
                                    btn-submit modal-btn-danger">
                                Sim
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>`;

        $('body').append(html);

        var $modal = $("#modal-confirm-confirm")
        var href = $(this).attr('data-href');
        var text = $(this).attr('data-text') ?? 'Editar';

        $modal.find('.modal-title').html(`${text}`);
        $modal.find('.btn-danger').html(`<i class="fas fa-exclamation-circle"></i> ${text}`);
        $modal.find('.modal-text-body').html(`Tem certeza que deseja ${text}?`);
        $modal.find('#form-confirm').attr('action', href)
        $modal.modal('show');

        $modal.on('hidden.bs.modal', function () {
            $modal.remove();
        })
    });

    $('.js-btn-delete').on('click', function () {
        var token = $('meta[name="csrf-token"]').attr('content');
        var action = $(this).attr('data-href') != undefined ? $(this).attr('data-href') : null;
        var html = `
        <div class="modal fade effect-scale" id="modal-confirm-delete" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title"></h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="mg-b-0 modal-text-body"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="modal-cancel" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <form id="form-delete" role="form" class="needs-validation" action="${action}" method="POST">
                            <input type="hidden" name="_token" value="${token}">
                            <input type="hidden" name="_method" value="DELETE">
                            <button type="submit" id="modal-confirm" data-btn-text="Deletando" class="btn btn-danger
                                    btn-submit modal-btn-danger">
                                Deletar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>`;

        $('body').append(html);

        var $modal = $("#modal-confirm-delete")
        var href = $(this).attr('data-href');
        var text = $(this).attr('data-text') ?? 'Deletar';

        $modal.find('.modal-title').html(`${text}`);
        $modal.find('.btn-danger').html(`<i class="fas fa-exclamation-circle"></i> ${text}`);
        $modal.find('.modal-text-body').html(`Tem certeza que deseja ${text}?`);
        $modal.find('#form-delete').attr('action', href)
        $modal.modal('show');

        $modal.on('hidden.bs.modal', function () {
            $modal.remove();
        })
    });

    $('.select2').select2({
        width: '100%',
    });

    $('select').on('select2:open', (event) => {
        if (!event.target.multiple) {
            document.querySelector('.select2-search__field').focus();
        }

    });

    $('.select2Multiple').select2({
        width: '100%',
        closeOnSelect: false
    });

    $('.date').mask('00/00/0000');

    $('.money').mask('000.000.000.000.000,00', { reverse: true });

    $('.rg').mask('00000000-0');

    $('[data-toggle="tooltip"]').tooltip()

    $('.date_time').mask('00/00/0000 00:00');

    $('.game_type').mask('0v0');

    $('.phone').mask('(00) 0000-0000');

    $('.cep').mask('00.000-000');

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
        spOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

    var CpfCnpjMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length <= 11 ? '000.000.000-009' : '00.000.000/0000-00';
    },
        cpfCnpjpOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(CpfCnpjMaskBehavior.apply({}, arguments), options);
            }
        };

    $('.sp_celphones').mask(SPMaskBehavior, spOptions);
    $('.cpfcnpj').mask(CpfCnpjMaskBehavior, cpfCnpjpOptions);

    /**
    * ToastR
    */
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-bottom-center",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "800",
        "timeOut": "3000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

})(jQuery)

var elements = document.getElementsByClassName("btn-submit");

var formSubmit = function () {
    var form = this.closest("form");
    var isValid = true;
    var text = this.getAttribute("data-btn-text");
    var textBack = this.innerHTML;
    text = text != null ? text : 'Salvando...';
    this.innerHTML = `<i class='fa fa-spinner fa-spin'></i> ${text}`;
    this.disabled = true;
    $.each(form.elements, function (index, value) {
        value.classList.remove('is-invalid')

        if (value.value === '' && value.hasAttribute('required')) {
            value.classList.add('is-invalid')
            isValid = false;
        }
        value.addEventListener('keyup', (e) => {
            if (e.value != '') {
                value.classList.remove('is-invalid')
            }
        });
    });

    if (isValid) {
        form.submit()
    } else {
        this.innerHTML = textBack;
        this.disabled = false;
    }
};

for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', formSubmit, false);
}


function checked() {
    if (alugadas.checked) {
        $('.playlist-th__alugadas').removeClass('d-none');
        $('.playlist-td__alugadas').removeClass('d-none');
        $('.playlist-td__vagas').attr('colspan', '0');
        $('.playlist-th__vagas').attr('colspan', '0');
    } else {
        $('.playlist-th__alugadas').addClass('d-none');
        $('.playlist-td__alugadas').addClass('d-none');
        $('.playlist-td__vagas').attr('colspan', '2');
        $('.playlist-th__vagas').attr('colspan', '2');
    }

    if (vagas.checked) {
        $('.playlist-th__vagas').removeClass('d-none');
        $('.playlist-td__vagas').removeClass('d-none');
        $('.playlist-td__alugadas').attr('colspan', '0');
        $('.playlist-th__alugadas').attr('colspan', '0');
    } else {
        $('.playlist-th__vagas').addClass('d-none');
        $('.playlist-td__vagas').addClass('d-none');
        $('.playlist-td__alugadas').attr('colspan', '2');
        $('.playlist-th__alugadas').attr('colspan', '2');
    }
}

window.numberFormat = number => {
    return number.toLocaleString('pt-br', {
        minimumFractionDigits: 2
    })
}

window.clearNumber = number => {
    if (number == '' || number == null) {
        return 0;
    }
    number = number.toString().replace("R$", "").replace(".", "").replace(".", "");
    number = number.replace(",", ".");
    return number != '' ? numeral(number).value() : 0;
}







